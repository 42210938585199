import { get, post } from './api';
/**
 * 查询登录人组织下员工信息列表(花名册)
 * @param params
 * @returns {*}
 */
export const getStaffInfo = (params) => post('/v1/org/queryOrgStaff/list', params);
/**
 * 查询公司所有员工信息列表(花名册)
 * @param {*} params
 */
export const getspecialQueryStaffInfo = (params) => post('/v1/org/specialQueryStaffInfo/list', params);
/**
 * 个人信息
 * @param params
 * @returns {*}
 */
export const getStaffInfoDetail = (params) => post('/v1/org/queryStaffInfoDetail', params);
export const getStaffInfoDetailTemp = (params) => post('/v1/org/queryStaffInfoTempDetail', params);
/**
 * 修改个人信息
 * @param params
 * @returns {*}
 */
export const getStaffUpdate = (params) => post('/v1/org/basic/info/update', params);
export const getStaffUpdateTemp = (params) => post('/v1/org/basic/info/updateTemp', params);
/**
 * 岗位信息
 * @param params
 * @returns {*}
 */
export const getStaffJobInfoDetail = (params) => post('/v1/org/queryStaffJobInfoDetail', params);
export const getStaffJobInfoDetailTemp = (params) => post('/v1/org/queryStaffJobInfoTempDetail', params);
/**
 * 成长记录
 * @param params
 * @returns {*}
 */
export const getGrowthRecord = (params) => post('/v1/org/queryGrowthRecord/list', params);
/**
 * 添加修改教育信息
 * @param params
 * @returns {*}
 */
export const saveStaffEducationInfo = (params) => post('/v1/org/saveStaffEducationInfo', params);
export const saveStaffEducationInfoTemp = (params) => post('/v1/org/saveStaffEducationInfoTemp', params);
/**
 * 添加编辑工作经历
 * @param params
 * @returns {*}/v1/org/saveStaffProjectExperience
 */
export const saveStaffWorkExperience = (params) => post('/v1/org/saveStaffWorkExperience', params);
export const saveStaffWorkExperienceTemp = (params) => post('/v1/org/saveStaffWorkExperienceTemp', params);
/**
 * 添加编辑项目经历
 * @param params
 * @returns {*}
 */
export const saveStaffProjectExperience = (params) => post('/v1/org/saveStaffProjectExperience', params);
export const saveStaffProjectExperienceTemp = (params) => post('/v1/org/saveStaffProjectExperienceTemp', params);
/**
 * 添加编辑家庭记录
 * @param params
 * @returns {*}
 */
export const saveStaffRelatives = (params) => post('/v1/org/saveStaffRelatives', params);
export const saveStaffRelativesTemp = (params) => post('/v1/org/saveStaffRelativesTemp', params);
/**
 * 添加编辑证书记录
 * @param params
 * @returns {*}
 */
export const saveStaffCertificate = (params) => post('/v1/org/saveStaffCertificate', params);
export const saveStaffCertificateTemp = (params) => post('/v1/org/saveStaffCertificateTemp', params);
/**
 * 添加编辑培训记录
 * @param params
 * @returns {*}
 */
export const saveStafftrainingRecordsInfo = (params) => post('/v1/org/saveStafftrainingRecordsInfo', params);
export const saveStafftrainingRecordsInfoTemp = (params) => post('/v1/org/saveStafftrainingRecordsInfoTemp', params);
/**
 * 编辑合同信息
 * @param params
 * @returns {*}
 */
export const saveStaffContractInfo = (params) => post('/v1/org/saveStaffContractInfo', params);
export const saveStaffContractInfoTemp = (params) => post('/v1/org/saveStaffContractInfoTemp', params);
/**
 * 查询岗位
 * @param params
 * @returns {*}
 */
export const queryStaffOffice = (params) => post('/v1/org/queryStaffOffice/list', params);
/**
 * 查询等级
 * @param params
 * @returns {*}
 */
export const queryStaffLevelSystem = (params) => post('/v1/org/queryStaffLevelSystem/list', params);

/**
 * 查询职务
 * @param params
 * @returns {*}
 */
export const queryPosition = (params) => post('/v1/org/all/position/list', params);
/**
 * 查询部门
 * @param params
 * @returns {*}
 */
export const queryDepartment = (params) => post('/v1/org/list', params);

/**
 * 查询组织原始数据
 * @param params
 * @returns {*}
 */
export const originalOrgList = (params) => post('/v1/org/originalOrgList', params);

/**
 * 保存组织负责人相关部门权限
 * @param {*} params
 * @returns
 */
export const saveOrgPermission = (params) => post('/v1/org/saveOrgPermission', params);

/**
 * 根据组织负责人ID获取权限列表
 * @param {*} params
 * @returns
 */
export const getOrgPermissionList = (params) => post('/v1/org/findOrgPermissionListByStaffId', params);

/**
 * 查询城市
 * @param params
 * @returns {*}
 */
export const getCityList = () => get('/dict/region.json');
/**
 * 查询职位
 * @param params
 * @returns {*}
 */
export const queryStaffJobList = (params) => post('/v1/org/queryStaffJob/list', params);

/**
 * 修改岗位信息
 * @param params
 * @returns {*}
 */
export const updateJob = (params) => post('/v1/org/basic/job/update', params);
export const updateJobTemp = (params) => post('/v1/org/basic/job/updateTemp', params);
/**
 * 查询成本中心
 * @param params
 * @returns {*}
 */
export const costCenter = (params) => post('/v1/costCenter/list', params);
/**
 * 查询汇报对象
 * @param params
 * @returns {*}
 */
export const selectPrincipal = (params) => post('/v1/common/selectPrincipal/list', params);
/**
 * 查询员工奖惩记录列表
 * @param params
 * @returns {*}
 */
export const queryRewardList = (params) => post('/v1/org/staff/reward/punish/list', params);
/**
 * 查询员工奖惩记录列表
 * @param params
 * @returns {*}
 */
export const addReward = (params) => post('/v1/org/staff/reward/punish/add', params);
/**
 * 查询员工奖惩信息
 * @param params
 * @returns {*}
 */
export const queryRewardDetails = (params) => post('/v1/org/staff/reward/punish/details', params);
/**
 * 查询员工晋升列表
 * @param params
 * @returns {*}
 */
export const queryStaffPromotionInfo = (params) => post('/v1/org/promotion/list', params);

/**
 * 修改员工晋升列表
 * @param params
 * @returns {*}
 */
export const saveStaffPromotionInfo = (params) => post('/v1/org/saveStaffPromotionInfo', params);
/**
 * 查询员工晋升详情
 * @param params
 * @returns {*}
 */
export const queryPromotionDetail = (params) => post('/v1/org/promotion/info', params);
/**
 * 特殊查询员工生日列表 - 查询所有员工信息
 * @param params
 * @returns {*}
 */
export const queryStaffBirInfo = (params) => post('/v1/org/specialQueryStaffBirInfo/list', params);
/**
 * 特殊查询员工生日列表 - 查询下级员工信息
 * @param params
 * @returns {*}
 */
export const StaffBirInfo = (params) => post('/v1/org/queryStaffBirInfo/list', params);
/**
 * 查询员工证照列表
 * @param params
 * @returns {*}
 */
export const queryLicence = (params) => post('/v1/org/queryLicence/list', params);
/**
 * 查公司证照列表
 * @param params
 * @returns {*}
 */
export const queryCompanyLicence = (params) => post('/v1/org/queryCompanyLicence/list', params);
/**
 * 新增/修改员工证照
 * @param params
 * @returns {*}
 */
export const saveLicencet = (params) => post('/v1/org/saveLicence', params);
export const saveLicencetTemp = (params) => post('/v1/org/saveLicenceTemp', params);
/**
 * 查询员工/公司证照详情
 * @param params
 * @returns {*}
 */
export const queryLicenceDetail = (params) => post('/v1/org/queryLicence/detail', params);
export const queryLicenceDetailTemp = (params) => post('/v1/org/queryLicence/detailTemp', params);
/**
 * web-app-文件上传接口(文件流)
 * @param params
 * @returns {*}
 */
export const uploadFile = (params) => post('/v1/common/upload/file', params);
/**
 * 删除员工证照
 * @param params
 * @returns {*}
 */
export const deleteLicence = (params) => post('/v1/org/deleteLicence', params);
export const deleteLicenceTemp = (params) => post('/v1/org/deleteLicenceTemp', params);
/**
 *  删除教育经历
 * @param params
 * @returns {*}
 */
export const deleteStaffEducationInfo = (params) => post('/v1/org/deleteStaffEducationInfo', params);
export const deleteStaffEducationInfoTemp = (params) => post('/v1/org/deleteStaffEducationInfoTemp', params);
/**
 *  删除工作经历
 * @param params
 * @returns {*}
 */
export const deleteStaffWorkExperience = (params) => post('/v1/org/deleteStaffWorkExperience', params);
export const deleteStaffWorkExperienceTemp = (params) => post('/v1/org/deleteStaffWorkExperienceTemp', params);
/**
 *  删除项目经历
 * @param params
 * @returns {*}
 */
export const deleteStaffProjectExperience = (params) => post('/v1/org/deleteStaffProjectExperience', params);
export const deleteStaffProjectExperienceTemp = (params) => post('/v1/org/deleteStaffProjectExperienceTemp', params);
/**
 *  删除培训经历
 * @param params
 * @returns {*}
 */
export const deleteStafftrainingRecordsInfo = (params) => post('/v1/org/deleteStafftrainingRecordsInfo', params);
export const deleteStafftrainingRecordsInfoTemp = (params) => post('/v1/org/deleteStafftrainingRecordsInfoTemp', params);
/**
 *  删除家庭状况
 * @param params
 * @returns {*}
 */
export const deleteStaffRelatives = (params) => post('/v1/org/deleteStaffRelatives', params);
export const deleteStaffRelativesTemp = (params) => post('/v1/org/deleteStaffRelativesTemp', params);
/**
 *  删除合同信息
 * @param params
 * @returns {*}
 */
export const deleteStaffContractInfo = (params) => post('/v1/org/deleteStaffContractInfo', params);
export const deleteStaffContractInfoTemp = (params) => post('/v1/org/deleteStaffContractInfoTemp', params);
/**
 * 查询字典
 * @param params
 * @returns {*}
 */
export const getDict = (params) => get('/v1/common/dict', params);
/**
 * 职族-职类-子类级联
 * @param params
 * @returns {*}
 */
export const getLinkage = (params) => post('/v1/profession/office/linkage', params);

/**
 * 通过职类/职类子类获取职位、职级 、职族代码
 * @param params
 * @returns {*}
 */
export const getRelevance = (params) => post('/v1/profession/office/relevance', params);

/**
 * 离职管理列表
 * @param params
 * @returns {*}
 */
export const getDimissonList = (params) => post('/v1/org/dimission/list', params);
/**
 * 离职管理详情
 * @param params
 * @returns {*}
 */
export const getDimissonInfo = (params) => post('/v1/org/dimission/info', params);
/**
 * 调动管理列表
 * @param params
 * @returns {*}
 */
export const getTransferList = (params) => post('/v1/org/transfer/list', params);
/**
 * 调动管理详情
 * @param params
 * @returns {*}
 */
export const getTransferInfo = (params) => post('/v1/org/transfer/info', params);
/**
 * 转正管理列表
 * @param params
 * @returns {*}
 */
export const getPositiveList = (params) => post('/v1/org/positive/list', params);
/**
 * 转正管理详情
 * @param params
 * @returns {*}
 */
export const getPositiveInfo = (params) => post('/v1/org/positive/info', params);

// 查询员工奖惩记录
export const getEmployeeRewardsAndPunishments = (params) =>
	post('/v1/org/queryRewardPunishRelatives', {
		request: params
	});
//录入员工
export const orgAddStaff = (params) =>
	post('/v1/org/add/staff', {
		request: params
	});
//录入员工草稿
export const orgAddDraftStaff = (params) =>
	post('/v1/org/add/staff/draft', {
		request: params
	});
// 员工删除
export const deleteStaff = (params) =>
	post('/v1/org/delete/staff', {
		request: params
	});
//员工删除记录
export const deleteStaffRecord = (params) =>
	post('/v1/org/delete/staff/list', {
		request: params
	});
//员工录入草稿
export const draftList = (params) =>
	post('/v1/org/staff/draft/list ', {
		request: params
	});
//员工录入草稿详情
export const DraftStaffInfo = (params) =>
	post('/v1/org/staff/draft/detail ', {
		request: params
	});
// 员工录入草稿删除
export const deleteDraft = (params) =>
	post('/v1/org/delete/staff/drafts', {
		request: params
	});
