<template>
	<el-dialog
		title="表头设置"
		center
		:visible.sync="settingDialog"
		:close-on-click-modal="false"
		@close="$emit('update:showTableCol', false)"
		width="700px"
	>
		<div>
			<div>
				<div style="font: bold 14px/25px 'Microsoft YaHei'">已选择</div>
				<div class="all_item">
					<span
						class="item_box"
						v-for="(dft, index) in defaultCols"
						:key="'dafalut' + index"
						style="background-color: #409eff; opacity: 45%; color: #fff"
						>{{ dft.name }}</span
					>
					<span
						v-for="(item, index) in showList"
						v-dragging="{ item: item, list: showList, group: 'item' }"
						:key="'show' + index"
						class="item_box item_box_active"
					>
						{{ item.name }}
					</span>
				</div>
			</div>
			<div>
				<div v-for="(it, indx) in chooseList" :key="indx">
					<div style="font: bold 14px/25px 'Microsoft YaHei'">{{ it.name }}</div>
					<div class="all_item">
						<span
							v-for="(item, index) in it.children"
							:key="'_' + index"
							@click="showBob(index, item, $event)"
							class="item_box"
							:class="{ item_box_active: getActive(item) }"
							>{{ item.name }}</span
						>
					</div>
				</div>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="handelCancel">取 消</el-button>
			<el-button type="primary" @click="settingEnter">确 定</el-button>
		</span>
	</el-dialog>
</template>
<script>
export default {
	props: {
		showTableCol: {
			type: Boolean,
			default: false
		},
		tableCols: {
			type: Array
		},
		defaultCols: {
			type: Array
		},
		chooseCols: {
			type: Array
		},
		maxDeps: {
			type: Number
		},
		aysnCols: {
			type: Array
		},
		defaultAll: {
			type: Array
		}
	},
	watch: {
		showTableCol() {
			this.settingDialog = this.showTableCol;
		},
		maxDeps(newVal, oldVal) {
			if (newVal !== oldVal) {
				const allShowList = [...this.allList];
				const workList = allShowList[0]?.children || [];

				for (let i = 0; i < newVal; i++) {
					workList.splice(0 + i, 0, this.aysnCols[i]);
				}
				allShowList[0].children = workList;
				this.chooseList = allShowList;
				// this.chooseList = allShowList.splice(1);
				// 给持久化数组更新
				if (localStorage.getItem('rosterShowCols')) {
					const showStr = JSON.parse(localStorage.getItem('rosterShowCols'));

					let arr = {};

					const showItems = [...this.showList];

					showItems.splice(0, showItems.length); // 清空数组
					for (let i = 0; i < showStr.length; i++) {
						arr = allShowList
							.reduce((a, b) => {
								if (Array.isArray(a)) {
									return a.concat(b?.children || []);
								}
								return a?.children?.concat(b?.children || []) || [];
							})
							.filter((item) => item.id == showStr[i].id);
						if (arr.length > 0) {
							showItems.push(arr[0]);
						}
					}
					for (let i = 0; i < newVal; i++) {
						showItems.splice(2 + i, 0, this.aysnCols[i]);
						this.spanIndex.push(this.aysnCols[i].id);
					}
					this.showList = showItems;
					// localStorage.setItem('rosterShowCols', JSON.stringify(this.showList));
					// this.showList = this.showList.splice(1);
				}
			}
		}
	},
	data() {
		return {
			settingDialog: false,
			showList: [],
			spanIndex: [],
			allList: [
				// {name: '序号',values:'id',id:'1' ,prop:'id'},
				{
					name: '工作信息',
					children: [
						// { name: '一级组织', values: 'orgNameOne', id: '1', prop: 'orgNameOne' },
						// { name: '二级组织', values: 'orgNameTwo', id: '2', prop: 'orgNameTwo' },
						// { name: '三级组织', values: 'orgNameThree', id: '3', prop: 'orgNameThree' },
						{ name: '职级', values: 'code', id: '4', prop: 'code' },
						{ name: '职位', values: 'officeName', id: '5', prop: 'officeName' },
						{ name: '岗位', values: 'jobName', id: '6', prop: 'jobName' },
						{ name: '工号', values: 'jobNumber', id: '7', prop: 'jobNumber' },
						{ name: '直接上级', values: 'repObjName', id: '8', prop: 'repObjName' },
						{ name: '工作邮箱', values: 'mailbox', id: '9', prop: 'mailbox' },
						{ name: '分机号', values: 'extensionNo', id: '10', prop: 'extensionNo' },
						{ name: '司龄', values: 'workingYears', id: '11', prop: 'workingYears' },
						{ name: '工作省份', values: 'workProvinceCode', id: '12', prop: 'workProvinceCode' },
						{ name: '工作城市', values: 'workCityCode', id: '13', prop: 'workCityCode' },
						{ name: '员工性质', values: 'employeeNature', id: '14', prop: 'employeeNature' },
						{ name: '入职时间', values: 'entryTime', id: '15', prop: 'entryTime' },
						{ name: '试用期', values: 'probationPeriod', id: '16', prop: 'probationPeriod' },
						{ name: '员工状态', values: 'status', id: '17', prop: 'status' },
						{ name: '转正时间', values: 'turnPositiveTime', id: '18', prop: 'turnPositiveTime' },
						{ name: '首次参加工作时间', values: 'firstWorkTime', id: '19', prop: 'firstWorkTime' }
					]
				},
				{
					name: '个人信息',
					children: [
						{ name: '年龄', values: 'age', id: '20', prop: 'age' },
						{ name: '性别', values: 'sexName', id: '21', prop: 'sexName' },
						{ name: '民族', values: 'nationality', id: '22', prop: 'nationality' },
						{ name: '政治面貌', values: 'politicalStatus', id: '23', prop: 'politicalStatus' },
						{ name: '身份证号', values: 'identityNum', id: '24', prop: 'identityNum' },
						{ name: '证件有效期', values: 'certificateValidity', id: '25', prop: 'certificateValidity' },
						{ name: '证件地址', values: 'certificateAddress', id: '26', prop: 'certificateAddress' },
						{ name: '籍贯', values: 'nativePlace', id: '27', prop: 'nativePlace' },
						{ name: '家庭地址', values: 'homeAddress', id: '28', prop: 'homeAddress' },
						{ name: '手机号', values: 'phoneNumber', id: '29', prop: 'phoneNumber' }
					]
				},
				{
					name: '学历信息',
					children: [
						{ name: '毕业院校', values: 'graduatedSchool', id: '30', prop: 'graduatedSchool' },
						{ name: '所学专业', values: 'profession', id: '31', prop: 'profession' },
						{ name: '最高学历', values: 'highestEducation', id: '32', prop: 'highestEducation' }
					]
				},
				{
					name: '银行信息',
					children: [
						{ name: '银行卡号', values: 'bankCardNo', id: '33', prop: 'bankCardNo' },
						{ name: '开户行', values: 'bankDeposit', id: '34', prop: 'bankDeposit' },
						{ name: '开户支行', values: 'bankBranch', id: '41', prop: 'bankBranch' }
					]
				},
				{
					name: '社保信息',
					children: [
						{ name: '社保账号', values: 'socialSecurityAccount', id: '35', prop: 'socialSecurityAccount' },
						{ name: '公积金账号', values: 'accumulationFund', id: '36', prop: 'accumulationFund' },
						{ name: '社保省份', values: 'hpfProvinceCode', id: '37', prop: 'hpfProvinceCode' },
						{ name: '社保公积金城市', values: 'hpfCityCode', id: '38', prop: 'hpfCityCode' }
					]
				},
				{
					name: '紧急联络人信息',
					children: [
						{ name: '紧急联络人', values: 'emergencyContact', id: '39', prop: 'emergencyContact' },
						{ name: '紧急联络人电话', values: 'emergencyContactPhone', id: '40', prop: 'emergencyContactPhone' }
					]
				}
				// { name: '姓名', values: 'name', id: '2', prop: 'name' },
				// { name: '工号', values: 'jobNumber', id: '3', prop: 'jobNumber' },
				// { name: '性别', values: 'sexName', id: '4', prop: 'sexName' },
				// { name: '年龄', values: 'age', id: '14', prop: 'age' },
				// { name: '职级', values: 'code', id: '15', prop: 'code' },
				// { name: '职位', values: 'officeName', id: '16', prop: 'officeName' },
				// { name: '直接上级', values: 'repObjName', id: '17', prop: 'repObjName' },
				// { name: '政治面貌', values: 'politicalStatus', id: '18', prop: 'politicalStatus' },
				// { name: '入职日期', values: 'entryTime', id: '19', prop: 'entryTime' },
				// { name: '手机号码', values: 'phoneNumber', id: '20', prop: 'phoneNumber' },
				// { name: '工作邮箱', values: 'mailbox', id: '21', prop: 'mailbox' },
				// { name: '毕业院校', values: 'graduatedSchool', id: '22', prop: 'graduatedSchool' },
				// { name: '专业', values: 'profession', id: '23', prop: 'profession' },
				// { name: '最高学历', values: 'highestEducation', id: '24', prop: 'highestEducation' },
				// { name: '婚姻状况', values: 'maritalStatus', id: '25', prop: 'maritalStatus' },
				// { name: '户籍所在地', values: 'nativePlace', id: '26', prop: 'nativePlace' },
				// { name: '身份证号码', values: 'identityNum', id: '27', prop: 'identityNum' },
				// { name: '人员类型', values: 'hireForm', id: '28', prop: 'hireForm' },
				// { name: '入职日期', values: 'entryTime', id: '29', prop: 'entryTime' },
				// { name: '转正日期', values: 'turnPositiveTime', id: '30', prop: 'turnPositiveTime' },
				// { name: '工作地省份', values: 'workProvinceCode', id: '31', prop: 'workProvinceCode' },
				// { name: '工作地城市', values: 'workCityCode', id: '32', prop: 'workCityCode' },
				// { name: '社保地省份', values: 'hpfProvinceCode', id: '33', prop: 'hpfProvinceCode' },
				// { name: '社保地城市', values: 'hpfCityCode', id: '34', prop: 'hpfCityCode' },
				// { name: '民族', values: 'nationality', id: '35', prop: 'nationality' },
				// { name: '紧急联络人', values: 'emergencyContact', id: '36', prop: 'emergencyContact' },
				// { name: '紧急联络人电话', values: 'emergencyContactPhone', id: '37', prop: 'emergencyContactPhone' },
				// { name: '家庭住址', values: 'homeAddress', id: '38', prop: 'homeAddress' },
				// { name: '转正状态', values: 'status', id: '39', prop: 'status' },
				// { name: '等级', values: 'level', id: '40', prop: 'level' },
				// { name: '职务', values: 'postName', id: '41', prop: 'postName' },
				// { name: '岗位', values: 'jobName', id: '42', prop: 'jobName' },
				// { name: '成本中心', values: 'centerName', id: '43', prop: 'centerName' }
				// { name: '首次参加工作时间', values: 'firstWorkTime', id: '44', prop: 'firstWorkTime' }
			],
			chooseList: []
		};
	},
	mounted() {
		if (localStorage.getItem('rosterShowCols')) {
			const localShowList = JSON.parse(localStorage.getItem('rosterShowCols'));

			for (let i = 0; i < localShowList.length; i++) {
				this.spanIndex.push(localShowList[i].id);
			}
			this.showList = localShowList.splice(1);
		}
	},
	methods: {
		getActive(item) {
			if (this.showList.length) {
				for (let i = 0; i < this.showList.length; i++) {
					if (this.showList[i].id == item.id) {
						return true;
					}
				}
			}
		},
		handelCancel() {
			this.$emit('update:showTableCol', false);
		},
		settingEnter() {
			if (this.showList.length > 0) {
				this.$emit('concatCol', this.showList);
			} else {
				this.$emit('concatCol', this.defaultAll);
			}
			this.$emit('update:showTableCol', false);
		},
		showBob(index, item, eve) {
			const arrIndex = this.spanIndex.indexOf(item.id);

			if (arrIndex > -1) {
				eve.target.classList.remove('item_box_active');
				this.spanIndex.splice(arrIndex, 1);
				this.showList = this.showList.filter((list) => list.id != item.id);
			} else if (this.showList.length < 12) {
				eve.target.classList.add('item_box_active');
				this.spanIndex.push(item.id);
				this.showList.push(item);
			} else {
				this.$message.warning('为了列表的展示，请勿选择超过十二个表头。');
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.all_item {
	display: flex;
	flex-wrap: wrap;
	min-height: 80px;
	padding: 20px 0 0 10px;
}
.item_box {
	min-width: 100px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	margin: 0 20px 20px 0;
	border-radius: 8px;
	font-size: 12px;
	background-color: #f5f5f5;
	color: #606266;
	border: 1px solid #f5f5f5;
	cursor: pointer;
}
.item_box_active {
	background-color: #409eff; // #409eff
	color: #fff;
	border: none;
}
</style>
