<template>
	<div style="width: 100%">
		<el-table
			id="printTable"
			class="staff staff-table tableBackground"
			:data="tableData.list"
			@row-click="handleRowClick"
			slot="empty"
			@selection-change="handleSelectionChange"
			v-loading="loadding"
			style="width: 100%"
		>
			<el-table-column type="selection" align="center" :width="80" v-if="deleteStatus"> </el-table-column>
			<el-table-column type="index" label="序号" align="center" :width="60" :index="indexMethod"> </el-table-column>
			<el-table-column
				class="staff"
				v-for="(cols, index) in tableCols"
				:key="`col_${index + cols.id}`"
				:prop="tableCols[index].prop"
				:label="cols.name"
				:width="cols.width"
				align="center"
			>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
export default {
	name: 'stafftable',
	components: {},

	props: {
		tableCols: {},
		tableData: {},
		deleteStatus: {},
		tabIndex: { type: Number, default: () => 1 },
		loadding: { type: Boolean, default: () => false },
		pagenation: {}
	},
	data() {
		return {
			rowId: 0,
			multipleSelection: []
		};
	},
	computed: {},
	methods: {
		indexMethod(index) {
			return index + (this.pagenation.pageNo - 1) * this.pagenation.pageSize + 1;
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		handleRowClick(row) {
			console.log(this.$parent.pageName, 'pageName');
			if (!this.deleteStatus && this.$parent.index) {
				this.rowId = row.id;
				let indx = this.tabIndex;

				switch (this.$parent.pageName) {
					case 'Roster':
						indx = 3;
						break;
					case 'RosterSet':
						indx = 4;
						break;

					default:
						break;
				}
				this.$router.push({
					path: '/user_info',
					query: {
						id: this.rowId,
						index: indx,
						jobId: row.jobId
					}
				});
			} else if (!this.$parent.index) {
				this.$router.push({ path: '/addhumanaffairs', query: { id: row.id } });
			}
		}
	},
	mounted() {}
};
</script>
<style lang="scss" scoped></style>
