<template>
	<div class="container" v-loading="loading">
		<div class="topBox" v-show="index">
			<div class="topLeft">
				<div
					v-for="(item, inx) in detailInfo.hireForm"
					:key="item.dictId + inx"
					:class="`${showStatus === `${item.groupId}${item.dictId}` ? 'active' : ''} content ${inx === 0 ? 'pass' : 'stop'}`"
					@click="handleSearch(item)"
					:style="inx + 1 === detailInfo.hireForm.length && `margin-right: 100px`"
				>
					<span>{{ item.number || 0 }}</span>
					<h4>{{ item.dictName }}</h4>
				</div>
				<div
					v-for="item in detailInfo.status"
					:key="item.dictId"
					:class="`${showStatus === `${item.groupId}${item.dictId}` ? 'active' : ''} content stop`"
					@click="handleSearch(item)"
				>
					<span>{{ item.number || 0 }}</span>
					<h4>{{ item.dictName }}</h4>
				</div>
			</div>
		</div>
		<el-row style="width: 100%; margin-top: 15px">
			<el-col :span="5" class="rzxyys">
				<div class="inputBox">
					<span>姓名</span>
					<el-input v-model="params.name" size="small" style="width: 180px" placeholder="请输入姓名"></el-input>
				</div>
			</el-col>
			<el-button
				type="primary"
				v-show="!index"
				size="small"
				class="searchBtn"
				icon="el-icon-search"
				style="height: 32px; margin-left: 0px"
				@click="handleSearch()"
				>查询</el-button
			>
			<div class="tool1Box" v-show="!index">
				<el-button v-show="deleteStatus" size="small" icon="el-icon-refresh-left" style="margin-left: 10px" @click="deleteStatus = false"
					>取消删除</el-button
				>
				<el-button
					v-show="!index"
					type="primary"
					size="small"
					icon="el-icon-delete"
					style="margin-left: 10px"
					@click="changedeleteDraftStatus"
					v-if="permissionControlBtns(pageName, 'DraftDelete')"
					>{{ deleteStatus ? '确定删除' : '草稿删除' }}</el-button
				>
				<el-button
					type="primary"
					v-show="!deleteStatus"
					size="small "
					icon="el-icon-edit"
					style="float: right"
					@click="$router.push({ name: 'Addhumanaffairs' })"
					v-if="permissionControlBtns(pageName, 'Enter') && !isView"
					>录入</el-button
				>
			</div>
			<el-col :span="5" class="rzxyys" v-show="index">
				<div class="inputBox">
					<span>组织</span>
					<treeselect
						:props="depProps"
						:options="deps"
						:value="depsId"
						:clearable="isClearable"
						:accordion="isAccordion"
						:default-expand-all="true"
						:level="depsId"
						@getValue="getValue($event)"
						style="width: 180px; height: 32px"
					/>
				</div>
			</el-col>
			<el-col :span="5" class="rzxyys" v-show="index">
				<div class="inputBox">
					<span>工号</span>
					<el-input style="width: 180px" size="small" v-model="params.jobNumber" placeholder="请输入工号"></el-input>
				</div>
			</el-col>
			<el-col :span="5" class="rzxyys" v-show="index">
				<div class="inputBox">
					<span>手机号</span>
					<el-input v-model="params.phoneNumber" size="small" style="width: 180px" placeholder="请输入手机号"></el-input>
				</div>
			</el-col>
			<el-col :span="4" class="rzxyys" v-show="index">
				<div class="inputBox">
					<span>职位</span>
					<el-input v-model="params.officeName" size="small" style="width: 180px" placeholder="请输入职位名称"></el-input>
				</div>
			</el-col>
		</el-row>
		<!--<el-col :span="4" class="rzxyys">
        <div class="inputBox">
          <span>状态</span>
          <el-select v-model="params.status" placeholder="请选择录入状态" size="small">
            <el-option v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>

        </div>
    </el-col>!-->
		<el-col :span="8" :class="index ? 'lastone' : ''" v-show="index">
			<div class="inputBox">
				<span>入职时间</span>
				<el-date-picker
					v-model="params.startTime"
					value-format="yyyy-MM-dd"
					format="yyyy-MM-dd"
					type="date"
					size="small"
					placeholder="请选择"
					@change="startChange"
					style="margin-right: 5px; width: 250px"
				></el-date-picker
				>-
				<el-date-picker
					v-model="params.endTime"
					value-format="yyyy-MM-dd"
					format="yyyy-MM-dd"
					type="date"
					size="small"
					placeholder="请选择"
					@change="endChange"
					style="margin-left: 5px; width: 250px"
				></el-date-picker>
			</div>
		</el-col>
		<el-button
			type="primary"
			size="small"
			v-show="index"
			class="searchBtn"
			icon="el-icon-search"
			style="height: 32px; margin-left: 0px"
			@click="handleSearch()"
			>查询</el-button
		>
		<el-button
			type="primary"
			size="small"
			v-show="!deleteStatus && index"
			icon="el-icon-setting"
			style="height: 32px; margin-left: 10px"
			@click="showTableCol = true"
			v-if="permissionControlBtns(pageName, 'Set')"
			>设置</el-button
		>
		<div v-show="index">
			<el-button
				type="primary"
				v-show="!deleteStatus"
				size="small "
				icon="el-icon-edit"
				style="margin-left: 10px"
				@click="$router.push({ name: 'Addhumanaffairs' })"
				v-if="permissionControlBtns(pageName, 'Enter') && !isView"
				>录入</el-button
			>
			<el-button
				type="primary"
				v-show="!deleteStatus"
				size="small "
				icon="el-icon-edit"
				style="margin-left: 10px"
				@click="$router.push({ name: 'DraftBox' })"
				v-if="permissionControlBtns(pageName, 'Draft') && !isView"
				>草稿箱</el-button
			>
			<el-button
				type="primary"
				v-show="!deleteStatus"
				size="small "
				icon="el-icon-upload2"
				style="margin-left: 10px"
				@click="openExport"
				v-if="permissionControlBtns(pageName, 'Import') && !isView"
				>导入</el-button
			>
			<el-button
				type="primary"
				size="small "
				v-show="!deleteStatus"
				icon="el-icon-download"
				style="margin-left: 10px"
				@click="outPort"
				v-if="permissionControlBtns(pageName, 'Export')"
				>导出</el-button
			>
			<el-button
				v-show="index == 1 || index === 2"
				type="primary"
				size="small"
				icon="el-icon-printer"
				style="margin-left: 10px"
				v-print="'#printTable'"
				v-if="permissionControlBtns(pageName, 'Print')"
				>打印</el-button
			>
			<el-button
				v-show="index == 2 && deleteStatus"
				size="small"
				icon="el-icon-refresh-left"
				style="margin-left: 10px"
				@click="deleteStatus = false"
				>取消删除</el-button
			>
			<el-button
				v-show="index == 2"
				type="primary"
				size="small"
				icon="el-icon-delete"
				style="margin-left: 10px"
				@click="changedeletestaff"
				v-if="permissionControlBtns(pageName, 'DeleteEmployee') && !isView"
				>{{ deleteStatus ? '确定删除' : '员工删除' }}</el-button
			>
			<el-button
				v-show="!deleteStatus"
				type="primary"
				size="small"
				icon="el-icon-delete"
				style="margin-left: 10px"
				@click="$router.push({ name: 'DeleteRecord' })"
				v-if="permissionControlBtns(pageName, 'DeleteRecord') && !isView"
				>员工删除记录</el-button
			>
		</div>
		<stafftable
			ref="stafftable"
			:tableCols="tableCols"
			:deleteStatus="deleteStatus"
			:loadding="loadding"
			:tabIndex="index"
			:pagenation="pagenation"
			:tableData="tableData"
			style="margin-top: 15px"
		></stafftable>
		<div style="width: 100%; float: right">
			<el-pagination
				class="fr"
				style="margin: 20px 0"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pagenation.pageNo"
				:page-sizes="[20, 30, 50, 100]"
				:page-size="pagenation.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="pagenation.totalNum"
			></el-pagination>
		</div>
		<importmodal @handleSearch="handleSearch" :importUrl="importUrl" :type="type" :dialogImportVisible.sync="dialogImportVisible"></importmodal>
		<tablecol
			@concatCol="concatCol"
			:defaultCols="defaultCols"
			:defaultAll="defaultAll"
			:maxDeps="maxDeps"
			:aysnCols="aysnCols"
			:showTableCol.sync="showTableCol"
			:tableCols="tableCols"
		></tablecol>
	</div>
</template>

<script>
import Sortable from 'sortablejs';
import stafftable from '@/components/table';
import * as getDatas from '@/api/workbench';
import treeselect from '@/components/treeSelect/treeSelect';
import importmodal from '@/components/import';
import tablecol from '@/views/components/components-col-set/index.vue';
import { getPositionLevelList } from '../../../../../api/staffSystem';
import { deleteStaff, deleteDraft } from '../../../../../api/workbench';
export default {
	name: 'workBench',
	components: {
		stafftable,
		treeselect,
		importmodal,
		tablecol
	},

	props: {
		index: { type: Number, default: () => 0 },
		pageName: {}
	},
	data() {
		return {
			type: 'importStaff',
			dialogImportVisible: false,
			// 分页参数
			pagenation: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			loading: false,
			deleteStatus: false,
			// 搜索条件对象
			params: {
				name: '',
				orgid: '',
				jobNumber: '',
				phoneNumber: '',
				officeId: '',
				officeName: '',
				startTime: '',
				endTime: '',
				pageNo: 1,
				pageSize: 20
			},
			options: [
				{
					value: '0',
					label: '全部'
				},
				{
					value: '1',
					label: '草稿'
				},
				{
					value: '2',
					label: '已录入'
				}
			],
			positionOpt: [],
			tableCols: [
				// { name: '序号', values: 'id', id: '1', prop: 'id' },
				{ name: '姓名', values: 'name', id: '0', prop: 'name', width: '80' },
				{ name: '工号', values: 'jobNumber', id: '7', prop: 'jobNumber' },
				{ name: '性别', values: 'sexName', id: '21', prop: 'sexName', width: '60' },
				{ name: '职位', values: 'officeName', id: '5', prop: 'officeName' },
				{
					name: '入职时间',
					values: 'entryTime',
					id: '15',
					prop: 'entryTime',
					width: '115'
				},
				{
					name: '手机号',
					values: 'phoneNumber',
					id: '29',
					prop: 'phoneNumber',
					width: '115'
				},
				{
					name: '工作邮箱',
					values: 'mailbox',
					id: '9',
					prop: 'mailbox',
					width: '200'
				}
			],
			aysnCols: [
				{ name: '一级组织', values: 'orgNameOne', id: '1', prop: 'orgNameOne' },
				{ name: '二级组织', values: 'orgNameTwo', id: '2', prop: 'orgNameTwo' },
				{
					name: '三级组织',
					values: 'orgNameThree',
					id: '3',
					prop: 'orgNameThree'
				},
				{
					name: '四级组织',
					values: 'orgNameFour',
					id: '8',
					prop: 'orgNameFour'
				},
				{
					name: '五级组织',
					values: 'orgNameFive',
					id: '9',
					prop: 'orgNameFive'
				},
				{
					name: '六级组织',
					values: 'orgNameSix',
					id: '10',
					prop: 'orgNameSix'
				},
				{
					name: '七级组织',
					values: 'orgNameSeven',
					id: '11',
					prop: 'orgNameSeven'
				},
				{
					name: '八级组织',
					values: 'orgNameEight',
					id: '12',
					prop: 'orgNameEight'
				},
				{
					name: '九级组织',
					values: 'orgNameNine',
					id: '13',
					prop: 'orgNameNine'
				}
			],
			tableData: [],
			tabIndex: '',
			loadding: false,
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			isClearable: true, // 可清空（可选）
			isAccordion: true, // 可收起（可选）
			deps: this.$store.state.setData.depsList,
			depsId: '',
			importUrl: '/v1/org/importStaffInfo',
			showTableCol: false,
			defaultCols: [
				// { name: '序号', values: 'id', id: '1', prop: 'id' },
				{ name: '姓名', values: 'name', id: '0', prop: 'name' }
				// { name: '工号', values: 'jobNumber', id: '7', prop: 'jobNumber' }
			],
			defaultAll: [
				// { name: '序号', values: 'id', id: '1', prop: 'id' },
				{ name: '姓名', values: 'name', id: '0', prop: 'name', width: '95' },
				{ name: '工号', values: 'jobNumber', id: '7', prop: 'jobNumber' },
				{ name: '性别', values: 'sexName', id: '21', prop: 'sexName', width: '60' },
				{ name: '职位', values: 'officeName', id: '5', prop: 'officeName' },
				{
					name: '入职时间',
					values: 'entryTime',
					id: '15',
					prop: 'entryTime',
					width: '115'
				},
				{
					name: '手机号码',
					values: 'phoneNumber',
					id: '29',
					prop: 'phoneNumber',
					width: '150'
				},
				{
					name: '工作邮箱',
					values: 'mailbox',
					id: '9',
					prop: 'mailbox',
					width: '200'
				}
			],
			maxDeps: 0,
			detailInfo: { hireForm: [], status: [] },
			showStatus: ''
		};
	},
	computed: {
		userInfo() {
			let str = sessionStorage.getItem('userInfo');

			if (str) {
				str = JSON.parse(str);
			} else {
				str = {};
			}
			return str;
		},
		isView() {
			return this.pageName === 'Roster';
		}
	},
	mounted() {
		// this.$store.dispatch('app/setPermission');
		// this.columnDrop();
		// this.getPositions();
		this.handleSearch();
		this.$nextTick(() => {
			localStorage.setItem('rosterShowCols', JSON.stringify(this.defaultAll));
			if (this.index) {
				this.getDetailInfo();
			}
		});
	},
	methods: {
		// 头部统计
		getDetailInfo() {
			this.$axios.post('/v1/org/queryStaffStatusDetail', { request: {} }).then((res) => {
				if (res?.code) {
					return;
				}
				const { hireForm, status } = res;

				this.detailInfo = {
					hireForm,
					status
					// hireForm: [hireForm[0]?.number, hireForm[1]?.number, hireForm[2]?.number, hireForm[3]?.number],
					// status: [status[0]?.number, status[1]?.number]
				};
			});
		},
		async getPositions() {
			this.loading = true;
			const res = await getPositionLevelList({ pageNo: 1, pageSize: 2000 });

			if (res._responseStatusCode === 0) {
				this.positionOpt = res.list;
				this.loading = false;
			}
		},
		changedeletestaff() {
			if (!this.deleteStatus) {
				this.deleteStatus = true;
			} else {
				const ids = [];

				this.$refs.stafftable.multipleSelection.map((it) => {
					ids.push(it.id);
				});
				if (ids.length < 1) {
					this.$message.warning('请勾选需要删除的员工');
				}
				const h = this.$createElement;

				this.$msgbox({
					title: '提示',
					message: h('p', null, [
						h('span', null, '已勾选员工删除后已关联的相关模块'),
						h('span', { style: 'color: #F56C6C' }, '数据会丢失，会严重影响其他模块业务'),
						h('span', null, ',您确认要删除吗？ ')
					]),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(() => {
					deleteStaff({ ids }).then((res) => {
						if (res._responseStatusCode == 0) {
							this.deletestaff = false;
							this.$message.success('员工已删除');
							this.handleSearch();
						}
					});
				});
			}
		},
		changedeleteDraftStatus() {
			if (!this.deleteStatus) {
				this.deleteStatus = true;
			} else {
				const ids = [];

				this.$refs.stafftable.multipleSelection.map((it) => {
					ids.push(it.id);
				});
				if (ids.length < 1) {
					this.$message.warning('请勾选需要删除的员工');
				} else {
					console.log('被选中的ids:', ids);
				}
				const h = this.$createElement;

				this.$msgbox({
					title: '提示',
					message: h('p', null, [
						// h('span',null,'已勾选员工删除后已关联的相关模块'),
						// h('span', { style: 'color: #F56C6C' }, '数据会丢失，会严重影响其他模块业务'),
						h('span', null, '您确认要删除草稿吗？ ')
					]),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(() => {
					deleteDraft({ ids }).then((res) => {
						console.log('草稿删除结果：', res);
						if (res._responseStatusCode == 0) {
							this.deleteStatus = false;
							this.$message.success('草稿已删除');
							this.handleSearch();
						}
					});
				});
			}
		},
		openExport() {
			const h = this.$createElement;

			this.$msgbox({
				title: '提示',
				message: h('p', null, [
					h('span', { style: 'color: #F56C6C' }, '已存在的员工信息若被修改，则不会被更新，需要您手动在平台内修改员工信息'),
					h('span', null, '，您确定要导入? ')
				]),
				showCancelButton: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.dialogImportVisible = true;
			});
		},
		// 部门选中的值
		getValue(eve) {
			if (eve === null) {
				this.depsId = '';
				this.params.orgid = '';
			} else {
				this.depsId = eve.id;
				this.params.orgid = eve.id;
			}
		},
		startChange(val) {
			this.params.startTime = `${val} 00:00:00`;
		},
		endChange(val) {
			this.params.endTime = `${val} 23:59:59`;
		},
		handleSearch(item) {
			this.loadding = true;
			const isadmin = ['1', '2'].includes(this.userInfo.userType);

			if (item) {
				this.showStatus = `${item.groupId}${item.dictId}`;
				if (item.groupId === 'hireForm' && item.dictId === '0') {
					this.params.groupId = '';
					this.params.dictId = '';
				} else {
					this.params.groupId = item.groupId;
					this.params.dictId = item.dictId;
				}
			}
			const params = {
				request: this.params
			};

			// this.tableCols = [...this.defaultAll];
			if (!this.index) {
				getDatas.draftList(this.params).then((result) => {
					this.maxDeps = result.maxLength;
					if (localStorage.getItem('rosterShowCols')) {
						this.tableCols = JSON.parse(localStorage.getItem('rosterShowCols'));
					}
					const orgList = this.tableCols.filter(({ values }) => values.includes('orgName'));

					if (this.maxDeps > 0 && !orgList.length) {
						for (let i = 0; i < this.maxDeps; i++) {
							this.tableCols.splice(3 + i, 0, this.aysnCols[i]);
						}
					}
					this.tableData = result;
					this.pagenation.totalNum = result.totalNum;
					this.loadding = false;
					this.selectPrincipal();
				});
			} else if (!isadmin) {
				getDatas
					.getStaffInfo(params)
					.then((result) => {
						this.maxDeps = result.maxLength;
						if (localStorage.getItem('rosterShowCols')) {
							this.tableCols = JSON.parse(localStorage.getItem('rosterShowCols'));
						}
						const orgList = this.tableCols.filter(({ values }) => values.includes('orgName'));

						if (this.maxDeps > 0 && !orgList.length) {
							for (let i = 0; i < this.maxDeps; i++) {
								this.tableCols.splice(3 + i, 0, this.aysnCols[i]);
							}
						}
						this.tableData = result;
						this.pagenation.totalNum = result.totalNum;
						this.loadding = false;
						this.selectPrincipal();
					})
					.catch(() => {});
			} else {
				getDatas
					.getspecialQueryStaffInfo(params)
					.then((result) => {
						this.maxDeps = result.maxLength;
						if (localStorage.getItem('rosterShowCols')) {
							this.tableCols = JSON.parse(localStorage.getItem('rosterShowCols'));
						}
						// else
						const orgList = this.tableCols.filter(({ values }) => values.includes('orgName'));

						if (this.maxDeps > 0 && !orgList.length) {
							for (let i = 0; i < this.maxDeps; i++) {
								this.tableCols.splice(3 + i, 0, this.aysnCols[i]);
							}
						}
						this.tableData = result;
						this.pagenation.totalNum = result.totalNum;
						this.loadding = false;
						this.selectPrincipal();
					})
					.catch(() => {});
			}
		},
		handleSizeChange(val) {
			this.pagenation.pageSize = val;
			this.params.pageSize = val;
			this.handleSearch();
		},
		handleCurrentChange(val) {
			this.pagenation.pageNo = val;
			this.params.pageNo = val;
			this.handleSearch();
		},
		// 导出
		outPort() {
			const outParams = {
				name: this.params.name,
				orgId: this.params.orgid,
				jobNumber: this.params.jobNumber,
				phoneNumber: this.params.phoneNumber,
				officeName: this.params.officeName,
				entryTimeBefore: this.params.startTime,
				entryTimeEnd: this.params.endTime
			};

			const outStr = encodeURIComponent(JSON.stringify(outParams));

			const url = `/v1/org/exportStaffInfo?params=${outStr}`;

			window.open(document.location.origin + url);
		},
		// 汇报对象
		selectPrincipal() {
			getDatas
				.selectPrincipal({
					request: {}
				})
				.then((res) => {
					this.$store.commit('SET_PRINCIPALLIST', res);
				})
				.catch(() => {});
		},

		// 合并表头
		concatCol(cols) {
			// this.tableCols = this.defaultCols.concat(cols);
			this.tableCols = cols;
			localStorage.setItem('rosterShowCols', JSON.stringify(this.tableCols));
		},
		// 列拖拽
		columnDrop() {
			const wrapperTr = document.querySelectorAll('.el-table__header-wrapper tr');

			const trs = this.index == 1 ? wrapperTr[0] : wrapperTr[1];

			this.sortable = Sortable.create(trs, {
				animation: 180,
				delay: 0,
				onEnd: (evt) => {
					let oldItem = {};

					oldItem = this.tableCols[evt.oldIndex];
					this.tableCols.splice(evt.oldIndex, 1);
					this.tableCols.splice(evt.newIndex, 0, oldItem);
					// console.log(this.tableCols)
				}
			});
		}
	}
};
</script>
<style lang="scss">
@mixin n-items-th($n) {
	&:first-child:nth-last-child(#{$n}),
	&:first-child:nth-last-child(#{$n}) ~ th {
		width: calc(100% / #{$n}) !important;
	}
}
@mixin n-items-td($n) {
	&:first-child:nth-last-child(#{$n}),
	&:first-child:nth-last-child(#{$n}) ~ td {
		width: calc(100% / #{$n}) !important;
	}
}
@media print {
	.staff-table {
		tr {
			width: 100vw;
			display: flex;
		}
		table thead th {
			width: 100vw;
			display: flex;
			th {
				@for $i from 4 to 22 {
					@include n-items-th($i);
				}
			}
		}
		table tr td {
			@for $i from 4 to 22 {
				@include n-items-td($i);
			}
		}
	}
}
@media (max-width: 1250px) {
	// .searchBtn {
	//   position: absolute;
	//   right: 6px;
	//   top: 54px;
	// }
	.lastone {
		margin-bottom: 30px;
	}
	.toolBox {
		transform: translateY(44px);
	}
}
// @media only screen and (min-width: 1350px) {
//     .rzxyys {
//       width: 20%;
//     }
//
//     .el-table {
//       margin-top: 30px;
//     }
// }
</style>
<style lang="scss" scoped>
.container {
	display: flex;
	flex-wrap: wrap;
	.topBox {
		width: 100%;
		height: 123px;
		color: #484848;
		.topLeft {
			width: 100%;
			height: 100%;
			padding: 5px 10px;
			display: inline-flex;
			margin-bottom: 10px;
		}
		.divider {
			vertical-align: baseline;
			height: 2em;
			display: inline-block;
			background: #dee2e9;
			width: 1px;
			margin: 0 8px;
			position: relative;
		}
		.content {
			display: inline-block;
			width: 13.185%;
			background: #f6f7fb;
			border-radius: 4px;
			text-align: center;
			padding: 25px 15px 15px;
			margin-right: 1.1%;
			line-height: 25px;
			margin-top: 10px;
			span {
				font-family: PingFangSC-Semibold;
				font-size: 32px;
				color: #484848;
				letter-spacing: -0.28px;
			}
			h4 {
				margin: 5px;
				font-weight: normal;
				font: bold 14px PingFangSC-Regular;
				color: #484848;
				letter-spacing: -0.25px;
				// padding-left: 55%;
				text-align: center;
			}
		}
		.all {
			span {
				color: #409eff;
			}
		}
		.waitaudit {
			span {
				color: #feb840;
			}
		}
		.pass {
			span {
				color: #00d6bf;
			}
		}
		.stop {
			span {
				color: #909399;
			}
		}
		.overrule {
			span {
				color: #909399;
			}
		}
		.content:hover,
		.active {
			background-color: #498df0;
			span,
			h4 {
				color: #fff;
			}
		}
	}
}
.toolBox,
.tool1Box {
	position: absolute;
	top: 57px;
	right: 0;
	.el-button {
		height: 32px;
	}
	div {
		float: right;
	}
}
.tool1Box {
	top: 0;
}
// .inputBox {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 10px;
//   span {
//     width: 100px;
//     text-align: right;
//     margin-right: 10px;
//   }
// }
</style>
