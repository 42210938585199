<template>
	<div class="app-container" style="padding-top: 0">
		<div style="margin: 10px 0px">
			<el-button size="medium" type="text" style="font-size: 16px; color: #333" @click="$router.go(-1)">返回</el-button>
		</div>
		<el-tabs v-model="activeName">
			<el-tab-pane label="草稿箱" name="1">
				<roster ref="draftBox1" :activeTab="activeName" v-if="activeName === '1'" pageName="DraftBox" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import roster from './roster.vue';
// import orgConfig from '../orgConfig.vue';
export default {
	name: 'draftBox',
	components: { roster },

	data() {
		return { activeName: '' };
	},

	mounted() {
		const data = JSON.parse(sessionStorage.getItem('draftBoxData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			'draftBoxData',
			JSON.stringify({
				activeName: this.activeName
				// listQuery: this.$refs[`draftBox${this.activeName}`].listQuery
			})
		);
		next();
	}
};
</script>

<style lang="scss" scoped></style>
